import React from 'react';
import "@mantine/core/styles.css";
import './App.css';
import { MantineProvider } from '@mantine/core';
import Home from './Components/Home';


function App() {
  return (
    <MantineProvider>
      <div className="App">
        <Home />
      </div>
    </MantineProvider>
  );
}

export default App;
