import React from 'react'
import{IconHexagonLetterD}  from "@tabler/icons-react";

const Header = () => {
    return (
        <nav className='flex justify-between bg-bgColor dm-mono-regular h-[15vh] items-center px-10'>
            <h1 className='text-primaryColor'>
                <IconHexagonLetterD size={59} stroke={1.3}/>
            </h1>
            <div className='text-textColor flex gap-4'>
                <a className='hover:text-primaryColor' href='#about' >
                    About
                </a>
                <a className='hover:text-primaryColor' href='#skills'  >
                    Skills
                </a>
                <a className='hover:text-primaryColor' href='#project'  >
                    Projects
                </a>
                <a className='hover:text-primaryColor' href='#Experience' >
                    Experience
                </a>
                <a className='hover:text-primaryColor' href='#Contact'  >
                    Contact
                </a>
            </div>
        </nav>
    )
}

export default Header